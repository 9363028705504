import React, {useContext, useEffect, useState} from "react";
import MusicPlayer from "./MusicPlayer";
import CssBaseline from "@mui/material/CssBaseline";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useDispatch} from "react-redux";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const TrackshareView = ({ share, imageQuality }) => {
    const dispatch = useDispatch();

    const play = () => {
        dispatch({type: 'play', track_id: share.track.id})
    }

    return (
        <div>
            <h1>Freigabe</h1>
            <p>{share.name}</p>
            <h3>Titel</h3>
            <CssBaseline/>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Interpret</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{share.track.track_name}</TableCell>
                            <TableCell>{share.track.artist_name}</TableCell>
                            <TableCell>

                                <IconButton
                                    aria-label={`Titel ${share.track.id} abspielen`}
                                    onClick={play}
                                >
                                    <PlayArrowIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <MusicPlayer imageQuality={imageQuality}/>
        </div>
    );
}

export default TrackshareView;