import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useRef, useState} from "react";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import SwipeableTextMobileStepper from "./SwipableTextMobileStepper";
import Paper from "@mui/material/Paper";
import {
    BottomNavigation,
    BottomNavigationAction,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip
} from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import Box from "@mui/material/Box";
import QueueIcon from "@mui/icons-material/Queue";
import WarningIcon from "@mui/icons-material/Warning";
import ClearIcon from '@mui/icons-material/Clear';
import {shuffle} from "../utils";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Typography from "@mui/material/Typography";
import {ConfigurationContext} from "../ConfigurationContext";
import DownloadIcon from "@mui/icons-material/Download";

const MusicPlayer = props => {
    const config = useContext(ConfigurationContext);

    const trackData = useSelector(state => state.trackData)
    const current_track_id = useSelector(state => state.current_track_id)
    const resetFlag = useSelector(state => state.playerResetFlag)
    const queue = useSelector(state => state.queue)
    const open = useSelector(state => state.playerOpen)
    const playing = useSelector(state => state.playing)

    const dispatch = useDispatch()

    const [currentTrack, setCurrentTrack] = useState(null)
    const [title, setTitle] = useState(null)
    const [url, setUrl] = useState(null)
    const [images, setImages] = useState([])
    const playerRef = useRef()

    /* Load track data and images */
    const load = async (id) => {
        if (typeof id === 'undefined' || id == null) {
            return undefined
        }
        try {
            const r = await fetch(`${config.apiUrl}/player/data/${id}`, {headers: config.getHeaders()})
            const t = await r.json()
            const track_data = {id: id, mp3_url: t.mp3_url, title: t.track_name, images: [], year: t.recording_year, date: t.recording_date, artist_firstname: t.first_name, artist_lastname: t.last_name, artist_name: t.artist_name, label_number: t.label_number, record_name: t.record_name}
            const r2 = await fetch(`${config.apiUrl}/player/images/${id}?quality=${props.imageQuality}`, {headers: config.getHeaders()})
            if (r2.ok) {
                const j = await r2.json()
                track_data['images'] = [j.front_image_url, j.back_image_url, ...j.artist_images, ...j.additional_images, ...j.other_artist_images].filter(e => e != null);
            }
            return track_data

        } catch (e) {
            return undefined
        }
    }

    const loadNext2InQueue = async (queue) => {
        const maxItemsToLoad = Math.min(2, queue.length)
        for (let i = 0; i < maxItemsToLoad; i++) {
            const id = queue[i]
            if (id in trackData)
                continue
            const r = await load(id)
            if (r)
                dispatch({type: "dataLoaded", trackData: {[id]: r}})
        }
    }

    const preprocessImages = inputs => {
        return shuffle(inputs).filter(i => i /* filter out null or empty */)
    }

    // process added queue items
    useEffect(() => {
        loadNext2InQueue(queue)
    }, [queue]);

    /* Load track url, images and title */
    useEffect(() => {
        if (!current_track_id) {
            setCurrentTrack(null)
            setTitle(null)
            setImages([])
            setUrl(null)
            return;
        }
        const currentTrackData = trackData[current_track_id]
        if (currentTrackData != undefined) {
            if (currentTrackData.mp3_url == null) {
                dispatch({type: "play", next: true})
                return
            }
            setUrl(currentTrackData.mp3_url)
            setTitle(currentTrackData.title)
            setCurrentTrack(currentTrackData)
            if (currentTrackData.images) {
                setImages(preprocessImages(currentTrackData.images))
            }
            else setImages([])
        }
        else {
            load(current_track_id).then(r => {
                if (r) {
                    dispatch({type: "dataLoaded", trackData: {[current_track_id]: r}})
                    dispatch({type: "play"})
                }
            })
        }
    }, [current_track_id, resetFlag])

    useEffect(() => {
        if ((currentTrack == null || currentTrack.id != current_track_id) && current_track_id in trackData) {
            const currentTrackData = trackData[current_track_id]
            setUrl(currentTrackData.mp3_url)
            setTitle(currentTrackData.title)
            setCurrentTrack(currentTrackData)
            if (currentTrackData.images)
                setImages(preprocessImages(currentTrackData.images))
            else setImages([])
        }
    }, [trackData]);

    /* Directly play next queue item */
    const onEnded = () => {
        const first = queue.length > 0 ? queue[0] : null
        if (first == null) {
            dispatch({type: "stop"})
        }
        else {
            dispatch({type: "play", next: true})
        }

    }

    const close = () => {
        dispatch({type: "setPlayerVisibility", visible: false})
    }

    const download = (e) => {
        if (!url || !currentTrack)
            return
        e.preventDefault();
        e.stopPropagation();
        const a = document.createElement('a');
        a.href = url;
        a.download = currentTrack.artist_name + " - " + currentTrack.title;
        a.click();
    }

    useEffect(() => {
        if (playerRef.current != undefined && playerRef.current != null) {
            try {
                if (playing) {
                    try {
                        playerRef.current.audio.current.play()
                    }
                    catch {
                        dispatch({type: "stop"})
                    }
                }
                else
                    playerRef.current.audio.current.pause()
            }
            catch (e)
            {
                console.error(e)
            }
        }
    }, [playing, playerRef.current]);

    return <>
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>

            <BottomNavigation
                showLabels
                style={{zIndex: 999999}}
            >
                {playing ?
                    <BottomNavigationAction label="Pause" icon={<PauseCircleIcon/>}
                                            onClick={() => dispatch({type: "pause"})}/> :
                    <BottomNavigationAction label="Play" icon={<PlayCircleIcon/>}
                                            onClick={() => dispatch({type: "play"})}/>
                }
                <BottomNavigationAction label="Player anzeigen" icon={<OpenInBrowserIcon/>}
                                        onClick={() => dispatch({type: "setPlayerVisibility", visible: true})}/>
                <BottomNavigationAction label="Warteschlange anzeigen" icon={<QueueIcon/>}
                                        onClick={() => dispatch({type: "setQueueVisibility", visible: true})}/>
                <BottomNavigationAction label={`Aktueller Titel: ${title ? title : "-"}`}/>
            </BottomNavigation>
        </Paper>
<QueueSidebar/>
        <Dialog
            fullScreen
            keepMounted
            open={open}
            onClose={close}
            style={{zIndex: 99999}}
        >
            <DialogTitle><IconButton
                aria-label="close"
                onClick={close}
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
                <IconButton
                    aria-label="download"
                    onClick={download}
                    sx={{
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <DownloadIcon/>
                </IconButton></DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={2}>
                    <div style={{marginLeft: "auto", marginRight: "auto"}}>
                        <Stack spacing={2}>
                            <div style={{textAlign: "center"}}>
                                <SwipeableTextMobileStepper images={images} advertisementImageUrls={
                                    [
                                        "gernotsrecordworld_ad_1.jpg",
                                        "gernotsrecordworld_ad_2.jpg",
                                        "gernotsrecordworld_ad_3.jpg",
                                        "gernotsrecordworld_ad_4.jpg"
                                    ]}/>
                                <Typography variant={"h4"} style={{textAlign: "center", marginTop: "15px"}}>{currentTrack && [currentTrack.track_name, currentTrack.artist_name, currentTrack.recording_year, currentTrack.label_number].filter(i => i && i.length > 0).join(", ")}</Typography>
                                <div style={{textAlign: "center", marginTop: "15px"}}>
                                    {url != null ? <AudioPlayer
                                        src={url}
                                        style={{backgroundColor: 'transparent', boxShadow: 'none'}}
                                        showSkipControls
                                        ref={playerRef}
                                        autoPlayAfterSrcChange={true}
                                        onPlay={() => dispatch({type: "play"})}
                                        onPause={() => dispatch({type: "pause"})}
                                        onClickPrevious={() => dispatch({type: "previous"})}
                                        onClickNext={onEnded}
                                        onEnded={onEnded}
                                        playsinline
                                    /> : <div>Kein Titel gewählt bzw. kein MP3 vorhanden.</div>}
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </Stack>
                    </div>
                </Stack>
            </DialogContent>
        </Dialog>
    </>
}

const QueueSidebar = props => {
    const config = useContext(ConfigurationContext);
    const dispatch = useDispatch()

    const [trackCache, setTrackCache] = useState(new Map())
    const queue = useSelector(state => state.queue)
    const queueOpen = useSelector(state => state.queueOpen)

    useEffect(() => {
        const process = async () => {
            const no_mp3_known = queue.filter(q => trackCache.has(q) && trackCache.get(q).mp3_post_id == null)
            if (no_mp3_known.length > 0) {
                dispatch({type: "dequeue", track_ids: no_mp3_known})
                return
            }
            const unknownItems = queue.filter(q => !(trackCache.has(q)))
            if (unknownItems.length === 0)
                return
            const r = await fetch(`${config.apiUrl}/player/tracknames?${unknownItems.map(i => `&track_ids[]=${i}`).join("&")}`, {headers: config.getHeaders()})
            if (r.ok) {
                const j = await r.json()
                const newTrackCache = new Map(trackCache)
                for (const t of j) {
                    newTrackCache.set(t.id, t)
                }
                setTrackCache(newTrackCache)
                dispatch({type: "dequeue", track_ids: j.filter(t => t.mp3_post_id == null).map(t => t.id)})
            }
        }
        process()
    }, [queue]);

    const playTrack = id => {
        dispatch({type: "play", track_id: id})
    }

    const deleteTrackFromQueue = index => {
        dispatch({type: "dequeue", dequeueIndex: index})
    }

    const clearQueue = () => {
        dispatch({type: "clearQueue"})
    }

    return <Drawer
        anchor="right"
        open={queueOpen}
        onClose={_ => dispatch({type: "setQueueVisibility", visible: false})}

    >
        <Box
            sx={{width: 400, paddingTop: "35px"}}
            role="presentation"
        >
            <List>
                <ListItem>
                    <ListItemText>
                        Warteschlange
                    </ListItemText>
                    <ListItemButton onClick={clearQueue}>
                        <ListItemIcon>
                            <ClearIcon/>
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider/>
            <List>
                {queue.length == 0 ? <ListItem><ListItemText>Keine Titel in
                    Warteschlange</ListItemText></ListItem> : queue
                    .map((t, index) => {
                        if (!trackCache.has(t))
                            return <>{t} - Kein Name gefunden</>
                        const data = trackCache.get(t)
                        return <ListItem key={index}>
                            <ListItemButton onClick={() => playTrack(t)} disabled={data.mp3_post_id == null}>
                                <ListItemIcon>
                                    <PlayArrowIcon/>
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemText primary={data.track_name}/>
                            {!data.mp3_post_id && <ListItemText primary={<Tooltip title={"Keine MP3 vorhanden!"}><WarningIcon color="warning"/></Tooltip>}/>}
                            <ListItemButton onClick={() => deleteTrackFromQueue(index)}>
                                <ListItemIcon>
                                    <ClearIcon/>
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    })}
            </List>
        </Box>
    </Drawer>
}

export default MusicPlayer