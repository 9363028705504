import React, {useContext, useEffect, useState} from "react";
import MusicPlayer from "./MusicPlayer";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {ConfigurationContext} from "../ConfigurationContext";
import {useDispatch} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import QueueIcon from "@mui/icons-material/Queue";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

const ShareView = ({ share, imageQuality }) => {
  return (
    <div>
      <h1>Freigabe</h1>
      <p>{share.name}</p>
        <h3>Playlists</h3>
        <CssBaseline/>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Name</strong></TableCell>
                        <TableCell><strong>Anzahl an Titeln</strong></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {share.playlists.map((p, index) => <PlaylistRow key={index} share={share} playlist={p}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    <MusicPlayer imageQuality={imageQuality}/>
    </div>
  );
}

const PlaylistRow = props => {
    const config = useContext(ConfigurationContext);

    const [loading, setLoading] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [playlistOpen, setPlaylistOpen] = useState(false)
    const [addTrackOpen, setAddTrackOpen] = useState(false)
    const [addRecordOpen, setAddRecordOpen] = useState(false)
    const [tracks, setTracks] = useState([])

    const dispatch = useDispatch();

    const confirmDownloadPlaylistTracks = () => {
        if (!window.confirm(`Playlist ${props.playlist.name} herunterladen?`))
            return
        dispatch({type: 'addDownloads', downloads: tracks.map(t => {
                return {
                    url: t.track.mp3_url,
                    name: t.track.track_name
                }
            })})
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setPlaylistOpen(open)
    };

    const playTrack = (id) => {
        dispatch({type: 'play', track_id: id})
    }
    const enqueueTrack = (id) => {

        dispatch({type: 'enqueue', track_ids: [id]})
    }

    const play = () => {
        if (tracks.length > 1)
            dispatch({type: 'playPlaylist', track_ids: tracks.map(t => t.track_id)})
        if (tracks.length > 0)
            dispatch({type: 'play', track_id: tracks[0].track_id})
    }

    const enqueue = () => {
        dispatch({type: 'enqueue', track_ids: tracks.map(t => t.track_id)})
    }

    const load = () => {
        setLoading(true)
        let url = `${config.apiUrl}/player/shares/${props.share.id}/playlists/${props.playlist.id}/tracks`
        fetch(url, {headers: config.getHeaders()})
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else throw new Error("No results")
            })
            .then(j => {
                setTracks(j)
                setLoading(false)
            })
            .catch(_ => {
                setLoading(false)
                setTracks([])
            })
    }

    useEffect(() => {
        load()
    }, [props.playlist]);
    if (deleted)
        return <></>
    return <TableRow>
        <TableCell>{props.playlist.name}</TableCell>
        <TableCell>{loading ? <Skeleton variant="text" sx={{fontSize: '1rem'}}/> : tracks.length}</TableCell>
        <TableCell>

            <IconButton
                aria-label={`Playlist ${props.playlist.id} abspielen`}
                onClick={play}
            >
                <PlayArrowIcon/>
            </IconButton>
            <IconButton
                aria-label={`Playlist ${props.playlist.id} in die Warteschlange`}
                onClick={enqueue}
            >
                <QueueIcon/>
            </IconButton>
            <IconButton
                aria-label={`Playlist ${props.playlist.id} anzeigen`}
                onClick={toggleDrawer(true)}
            >
                <FormatListBulletedIcon/>
            </IconButton>
            <Drawer
                anchor="right"
                open={playlistOpen}
                onClose={_ => setPlaylistOpen(false)}
            >
                <Box
                    sx={{width: 400, paddingTop: "35px"}}
                    role="presentation"
                >
                    <List>
                        <ListItem>
                            <ListItemButton onClick={() => play()}>
                                <ListItemIcon>
                                    <PlayArrowIcon/>
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemButton onClick={() => enqueue()}>
                                <ListItemIcon>
                                    <QueueIcon/>
                                </ListItemIcon>
                            </ListItemButton>
                            <ListItemText>
                                Playlist "{props.playlist.name}"
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        {tracks.length == 0 ? <ListItem>
                            <ListItemText>Keine Titel in
                                Playlist</ListItemText>
                        </ListItem> : tracks.map((t, index) =>
                            <ListItem key={index}>
                                <Grid container style={{width: "100%"}}>
                                    <Grid item xs={2}>
                                        <ListItemButton onClick={() => playTrack(t.track.id)}
                                                        disabled={t.track.mp3_post_id == null}>
                                            <PlayArrowIcon/>
                                        </ListItemButton>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemButton onClick={() => enqueueTrack(t.track.id)}
                                                        disabled={t.track.mp3_post_id == null}>
                                            <QueueIcon/>
                                        </ListItemButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ListItemText primary={<div>{t.track.track_name}{t.track.mp3_post_id ? <></> : <Tooltip title={"Keine MP3 vorhanden!"}><span>&nbsp;<WarningIcon color="warning"/></span></Tooltip>}</div>}/>
                                    </Grid>
                                </Grid>

                            </ListItem>)}
                    </List>
                </Box>
            </Drawer>
            <IconButton
                aria-label={`Playlist ${props.playlist.id} herunterladen`}
                onClick={confirmDownloadPlaylistTracks}
            >
                <DownloadIcon/>
            </IconButton>
        </TableCell>
    </TableRow>
}

export default ShareView;