import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {persistor, store} from "./store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {ConfigurationContext} from "./ConfigurationContext";
import {createTheme, ThemeProvider} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const rootElem = document.getElementById('root')

let apiUrl = rootElem.getAttribute("data-api-url")
if (apiUrl == null)
    apiUrl = "https://api.gernots-recordworld.at"

//apiUrl = "http://localhost:8082"

let params = new URL(document.location).searchParams;
let apiUsername;
if (params.has("share_id"))
    apiUsername = params.get("share_id");
else if (params.has("trackshare_id"))
    apiUsername = params.get("trackshare_id");
let apiPassword = "jL7CTQp9RvnAeHXhFBKYPb"

const getHeaders = (contentTypeJson = false) => {
    const headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(apiUsername + ':' + apiPassword));
    if (contentTypeJson)
        headers.set("Content-Type", "application/json")

    return headers
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


const root = ReactDOM.createRoot(rootElem);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigurationContext.Provider value={{apiUrl, apiUsername, apiPassword, getHeaders}}>

                    <BrowserRouter>
                        <ThemeProvider theme={darkTheme}>
                            <CssBaseline/>
                            <App/>
                        </ThemeProvider>
                    </BrowserRouter>
                </ConfigurationContext.Provider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
