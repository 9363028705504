import {legacy_createStore} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
}


const initialState = {
    history: [], // player track history
    queue: [],
    downloadQueue: [],
    playing: false,
    current_track_id: null,
    playerOpen: false,
    playerResetFlag: false,
    queueOpen: false,
    trackData: {} // maps track_ids to track data
}

const changeState = (state = initialState, {type, ...rest}) => {
    switch (type) {
        case 'enqueue':
            if (rest.putToFront)
                return {...state, queue: [...rest.track_ids.filter(i => i), ...state.queue.filter(i => i)]}
            else return {...state, queue: [...state.queue.filter(i => i), ...rest.track_ids.filter(i => i)]}
        case 'dequeue':
            if (rest.dequeueIndex) {
                if (rest.dequeueIndex >= state.queue.length) return state
                let newQueue = [...state.queue]
                newQueue.splice(rest.dequeueIndex, 1)
                return {...state, queue: newQueue, current_track_id: null}
            }
            if (rest.track_ids) {
                return {...state, queue: state.queue.filter(i => !rest.track_ids.includes(i))}
            }
            return {...state, queue: state.queue.slice(1), current_track_id: null}
        case 'clearQueue':
            return {...state, queue: []}
        case 'play':
            if (rest.track_id)
                return {...state, playing: true, current_track_id: rest.track_id, queue: state.queue[0] === rest.track_id ? state.queue.slice(1) : state.queue, playerResetFlag: !state.playerResetFlag, history: [...state.history, rest.track_id]}
            if (state.queue.length > 0 && (rest.next || state.current_track_id == null)) {
                return {...state, playing: true, queue: state.queue.slice(1), current_track_id: state.queue[0], playerResetFlag: !state.playerResetFlag, history: [...state.history, state.queue[0]]}
            }
            if (!rest.next && state.current_track_id != null)
                return {...state, playing: true, playerResetFlag: !state.playerResetFlag}
            return {...state, playing: false}
        case 'playPlaylist':
            if (rest.track_ids && rest.track_ids.length > 0) {
                return {...state, playing: true, queue: rest.track_ids.slice(1), current_track_id: rest.track_ids[0], playerResetFlag: !state.playerResetFlag, history: [...state.history, rest.track_ids[0]]}
            }
            return state
        case 'previous':
            if (state.history.length >= 1) {
                if (state.history[state.history.length - 1] === state.current_track_id) { //last element in history is current
                    if (state.history.length > 1)
                        return {...state, history: state.history.slice(0, -1), current_track_id: state.history[state.history.length - 2], queue: state.current_track_id ? [state.current_track_id, ...state.queue.filter(i => i)] : state.queue}
                    else return state
                }
                else {
                    return {...state, history: state.history.slice(0, -1), current_track_id: state.history[state.history.length - 1], queue: state.current_track_id ? [state.current_track_id, ...state.queue.filter(i => i)] : state.queue}
                }
            }
            return state
        case 'pause':
            return {...state, playing: false}
        case 'stop':
            return {...state, playing: false, current_track_id: null}
        case 'setPlayerVisibility':
            return {...state, playerOpen: rest.visible}
        case 'setQueueVisibility':
            return {...state, queueOpen: rest.visible}
        case 'dataLoaded': {
            return {...state, trackData: {...state.trackData, ...rest.trackData}}
        }
        case 'addDownloads':
            return {...state, downloadQueue: [...state.downloadQueue, ...rest.downloads]}
        case 'removeDownload':
            if (state.downloadQueue.length <= 1) return {...state, downloadQueue: []}
            return {...state, downloadQueue: state.downloadQueue.slice(1)}
        default:
            return state
    }
}
const persistedReducer = persistReducer(persistConfig, changeState)


let store = legacy_createStore(persistedReducer)
let persistor = persistStore(store)
export {store, persistor}