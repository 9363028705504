import {createContext} from 'react';

export const ConfigurationContext = createContext(
    {
        apiUrl: "/index.php?rest_route=/grw-api/v1/configuration_error",
        apiUsername: "-",
        apiPassword: "-",
        getHeaders: () => new Headers()
    }
);
