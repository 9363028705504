import logo from './logo.svg';
import './App.css';
import React, {useContext, useEffect, useState} from "react";
import MusicPlayer from "./components/MusicPlayer";
import {BrowserRouter, useSearchParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import ShareView from "./components/ShareView";
import {ConfigurationContext} from "./ConfigurationContext";
import TrackshareView from "./components/TrackshareView";

function App() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [shareId, setShareId] = useState(null)
    const [trackshareId, setTrackshareId] = useState(null)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [shareData, setShareData] = useState(null)
    const [imageQuality, setImageQuality] = useState("original")
    const config = useContext(ConfigurationContext)

    useEffect(() => {
        if (!searchParams.has("share_id")) {
            setShareId(null)
        } else {
            setShareId(searchParams.get("share_id"))
        }
        if (!searchParams.has("trackshare_id")) {
            setTrackshareId(null)
        } else {
            setTrackshareId(searchParams.get("trackshare_id"))
        }
        if (searchParams.has("imageQuality")) {
            setImageQuality(searchParams.get("imageQuality"))
        }
    }, [searchParams])

    const loadPlaylistShare = async (shareId) => {
        const response = await fetch(config.apiUrl + "/player/shares/" + shareId, {
            headers: config.getHeaders()
        })
        if (!response.ok) {
            setError(true)
            return;
        }
        const data = await response.json()
        setError(false)
        setShareData(data)
        setLoading(false)
    }

    const loadTrackshare = async (shareId) => {
        const response = await fetch(config.apiUrl + "/player/trackshares/" + shareId, {
            headers: config.getHeaders()
        })
        if (!response.ok) {
            setError(true)
            return;
        }
        const data = await response.json()
        setError(false)
        setShareData(data)
        setLoading(false)
    }

    useEffect(() => {
        if (shareId != null) {
            setLoading(true)
            loadPlaylistShare(shareId).then(setLoading(false))
        }
        else if (trackshareId != null) {
            setLoading(true)
            loadTrackshare(trackshareId).then(setLoading(false))
        }
    }, [shareId, trackshareId]);

    if ((!searchParams.has("share_id") && !searchParams.has("trackshare_id")) || error || (!loading && shareData == null)) {
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <p>
                        Bitte verwenden Sie einen gültigen Freigabelink
                    </p>
                    <a
                        className="App-link"
                        href="https://gernots-recordworld.at"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Zurück zur Recordworld
                    </a>
                </header>
            </div>
        )
            ;
    }
    if (loading)
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <p>
                        <CircularProgress style={{color: "white"}}/>
                    </p>
                    <span>
                        Lade Daten...
                    </span>
                </header>
            </div>
        );
    if (shareId != null)
        return <div className="App">
            <header className="App-header">
                <ShareView share={shareData} imageQuality={imageQuality}/>
            </header>
        </div>
    else return <div className="App">
        <header className="App-header">
            <TrackshareView share={shareData} imageQuality={imageQuality}/>
        </header>
    </div>
}

export default App;
