import {autoPlay} from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import {useTheme} from "@mui/material/styles";
import React from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SwipeableTextMobileStepper = (props) => {
    const getRandomAdvertisement = () => {
        if (!props.advertisementImageUrls || props.advertisementImageUrls.length === 0) return null;
        return props.advertisementImageUrls[Math.floor(Math.random() * props.advertisementImageUrls.length)];
    }
    const images = (props.advertisementImageUrls && props.advertisementImageUrls.length > 0) ? props.images.reduce((list, elem, i) => {
        if(i === 0) list.push(props.advertisementImageUrls[0]);
        else if(i % props.advertisementInterval === 0) list.push(getRandomAdvertisement());
        list.push(elem);
        return list;
    }, []) : props.images;

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        if (!isNaN(step))
            setActiveStep(step);
        else setActiveStep(0);
    };

    return (
        <Box sx={{ maxWidth: '80vw', height: 'auto', flexGrow: 1, marginLeft: 'auto', marginRight: 'auto' }}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={9000}
            >
                {images.map((step, index) => (
                    <div key={index} style={{textAlign: "center"}}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                                component="img"
                                style={{
                                    width: '100%',  /*width of parent container*/
                                    height: 'auto',/*height of parent container*/
                                    maxHeight: '60vh',
                                    objectFit: 'contain',
                                    position: 'relative',
                                    top: '50%',
                                }}
                                src={step}
                            />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                variant="progress"
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </Button>
                }
            />
        </Box>
    );
}

SwipeableTextMobileStepper.defaultProps = {
    advertisementImageUrls: "",
    advertisementInterval: 5 // every 5th image is an advertisement
}

export default SwipeableTextMobileStepper